import React from 'react'
import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  Button,
  Form
} from 'reactstrap'
import { H3 } from './H123456'

export default props => (
  <section className='subscription'>
    <Container>
      <Row>
        <Col md={{ size: 8, offset: 2 }} className='text-center'>
          <H3 className='section-title'>Subscribe</H3>
          <p className='section-description'>
            Stay up to date! Get all the latest &amp; greatest posts delivered
            straight to your inbox.
          </p>
          <Form
            method='post'
            action='/subscribe/'
            className='subscription-form m-auto'
          >
            <Input className='confirm' name='confirm' type='hidden' />
            <Input
              className='location'
              name='location'
              value={'window.location.href'}
              type='hidden'
            />
            <Input
              className='referrer'
              name='referrer'
              value={'document.referrer'}
              type='hidden'
            />
            <InputGroup className='mb-3'>
              <Input
                bsSize='lg'
                name='email'
                placeholder='yourmail@example.com'
                type='email'
              />
              <div className='input-group-append'>
                <Button size='lg' color='primary' type='submit'>
                  Subscribe
                </Button>
              </div>
            </InputGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  </section>
)
