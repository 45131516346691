import React from 'react'
import kebabCase from 'lodash/kebabCase'

export const H1 = props => (
  <h1 {...props} id={kebabCase(props.children)}>
    {props.children}
  </h1>
)

export const H2 = props => (
  <h2 {...props} id={kebabCase(props.children)}>
    {props.children}
  </h2>
)

export const H3 = props => (
  <h3 {...props} id={kebabCase(props.children)}>
    {props.children}
  </h3>
)

export const H4 = props => (
  <h4 {...props} id={kebabCase(props.children)}>
    {props.children}
  </h4>
)

export const H5 = props => (
  <h5 {...props} id={kebabCase(props.children)}>
    {props.children}
  </h5>
)

export const H6 = props => (
  <h6 {...props} id={kebabCase(props.children)}>
    {props.children}
  </h6>
)
