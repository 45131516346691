import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { graphql, Link } from 'gatsby'
import isNil from 'lodash/isNil'

import { TagLink, HomeLink } from '../linkHelper'
import * as SocialIcon from '../components/icons'

const renderTagCloud = allTags => {
  if (isNil(allTags)) return null
  if (allTags.length === 0) return null
  return (
    <div className='tagcolud-wrapper'>
      <div className='title'>
        <span>Tags</span>
      </div>
      <div className='tagcloud'>
        {allTags.map(({ node }) => (
          <TagLink key={node.id} slug={node.slug}>
            {node.title}
          </TagLink>
        ))}
      </div>
    </div>
  )
}

const renderSocialLinks = props => (
  <div className='social-links-wrap'>
    <ul className='social-links'>
      <li>
        <a href='#'>
          <SocialIcon.Facebook />
        </a>
      </li>
      <li>
        <a href='#'>
          <SocialIcon.Twitter />
        </a>
      </li>
      <li>
        <a href='#'>
          <SocialIcon.Medium />
        </a>
      </li>
      <li>
        <a href='#'>
          <SocialIcon.LinkedIn />
        </a>
      </li>
    </ul>
  </div>
)

const renderCopyright = props => (
  <div className='copyright'>
    <p>
      Copyright © 2018 <HomeLink>sibest.</HomeLink> - All right Reserved.{' '}
      <Link to='/privacy'>Privacy</Link>
    </p>
  </div>
)

export default props => (
  <footer className='site-footer text-center'>
    <Container>
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          {renderTagCloud(props.allTags)}
          <div className='separator' />
          {renderSocialLinks()}
          {renderCopyright()}
        </Col>
      </Row>
    </Container>
  </footer>
)

export const FooterTags = graphql`
  fragment FooterTags on ContentfulTagConnection {
    edges {
      node {
        id
        title
        slug
      }
    }
  }
`
