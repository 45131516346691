import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Helmet from 'react-helmet'

import Menu from '../components/menu'
import Subscription from '../components/subscription'
import Footer from '../components/footer'

import '../main.scss'

const LayoutQueryWrap = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allContentfulTag {
          ...FooterTags
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          defaultTitle={data.site.siteMetadata.title}
          titleTemplate={`%s | ${data.site.siteMetadata.title}`}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' }
          ]}
        >
          <html lang='it' />
          <link
            href='https://fonts.googleapis.com/css?family=Lora:400,700|Open+Sans:400,400i,700,700i'
            rel='stylesheet'
          />
        </Helmet>
        <header>
          <Menu active='/' />
        </header>
        {children}
        <Subscription />
        <Footer allTags={data.allContentfulTag.edges} />
      </>
    )}
  />
)

export default LayoutQueryWrap
