import React, { Component } from 'react'
import throttle from 'lodash/throttle'
import { Link } from 'gatsby'
import {
  Nav,
  NavItem,
  Container,
  Navbar,
  NavbarToggler,
  Collapse
} from 'reactstrap'

import { HomeLink } from '../linkHelper'

let active = null

export default class Menu extends Component {
  constructor (props) {
    super(props)

    this.lastActive = active
    active = props.active

    this.state = {
      isOpen: false,
      show: true,
      sticky: false
    }
  }

  lastScroll = {
    y: 0,
    timeStamp: 0
  }

  barHeight = 72

  onScroll = throttle(
    ev => {
      const currScroll = {
        y: window.scrollY > 0 ? window.scrollY : 0,
        timeStamp: ev.timeStamp
      }

      const stateToSet = {}

      if (currScroll.y > this.barHeight) {
        stateToSet.sticky = true
      } else {
        stateToSet.sticky = false
      }

      if (this.state.show && currScroll.y > this.lastScroll.y) {
        stateToSet.show = false
        stateToSet.isOpen = false
      } else if (!this.state.show && currScroll.y <= this.lastScroll.y) {
        stateToSet.show = true
      }

      this.setState(stateToSet)
      this.lastScroll = currScroll
    },
    250,
    { leading: true, trailing: true }
  )

  toggle = () => {
    this.setState(state => ({
      ...state,
      isOpen: !state.isOpen,
      sticky: state.isOpen === false ? true : state.sticky
    }))
  }

  componentDidMount () {
    this.lastScroll.y = window.scrollY
    // this.onScroll({})

    document.addEventListener('scroll', this.onScroll)
  }

  shouldComponentUpdate (newProps, newState) {
    return (
      newProps.active !== this.props.active ||
      newState.isOpen !== this.state.isOpen ||
      newState.show !== this.state.show ||
      newState.sticky !== this.state.sticky
    )
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.onScroll)

    active = this.lastActive
  }

  render () {
    let navbarClassNames = ['site-navbar']
    if (this.state.sticky) navbarClassNames.push('sticky')
    if (this.state.show) navbarClassNames.push('show')
    else navbarClassNames.push('hide')

    return (
      <Navbar
        fixed='top'
        light
        expand='md'
        className={navbarClassNames.join(' ')}
      >
        <Container>
          <HomeLink className='text-logo mr-auto'>sibest.</HomeLink>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='ml-auto main-menu navbar-expand-md' navbar>
              <NavItem>
                <Link className='nav-link' to='/author/simone-andreani'>
                  About
                </Link>
              </NavItem>
              <NavItem>
                <Link className='nav-link' to='/contact'>
                  Contact
                </Link>
              </NavItem>
              {/* <NavItem>
                <NavLink href='https://github.com/reactstrap/reactstrap'>
                  GitHub
                </NavLink>
              </NavItem> */}
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Options
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Option 1</DropdownItem>
                  <DropdownItem>Option 2</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Reset</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    )
  }
}
