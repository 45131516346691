import React from 'react'
import { Link } from 'gatsby'
import pick from 'lodash/pick'
import last from 'lodash/last'

const removeTrailingSlash = str => (last(str) === '/' ? str : str)
const whitelist = ['title', 'className']

const InternalLink = to => props => (
  <Link to={removeTrailingSlash(to)} {...pick(props, whitelist)}>
    {props.children}
  </Link>
)

const SlugLink = (...elements) => props => (
  <Link
    to={removeTrailingSlash(`${elements.join('/')}/${props.slug}`)}
    {...pick(props, whitelist)}
  >
    {props.children}
  </Link>
)

export const HomeLink = InternalLink('/')
export const PostLink = SlugLink()
export const AuthorLink = SlugLink('author')
export const TagLink = SlugLink('tag')
